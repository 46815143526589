import { InputMask } from "@react-input/mask";
import { Suspense, useCallback, useContext, useRef } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Local01 from "../assets/images/images/local-01.png";
import Local02 from "../assets/images/images/local-02.png";
import Local03 from "../assets/images/images/local-03.png";
import LogoFullImage from "../assets/images/images/logo-full.png";
import Step01 from "../assets/images/images/step-01.png";
import Step02 from "../assets/images/images/step-02.png";
import Step03 from "../assets/images/images/step-03.png";
import WinesImage from "../assets/images/images/wines.png";
import WomanFooterImage from "../assets/images/images/woman-footer.png";
import Field from "../components/Field";
import useApi from "../hooks/useApi";
import { AppContext } from "../providers/AppProvider";
import {
  validateBirthDate,
  validateCPF,
  validateMail,
  validateName,
  validatePhone
} from "../utils";

export function Component() {
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const app = useContext(AppContext);
  const api = useApi();

  const { formState, register, handleSubmit, setError, getValues } = useForm({
    defaultValues: {
      nome: "",
      cpf: "",
      data_nascimento: "",
      telefone: "",
      email: "",
      email_confirmation: "",
      password: "",
      password_confirmation: "",
      aceite_regulamento: false,
      aceite_informacoes: false,
      recaptcha_token: "",
      sexo: "",
    },
  });

  const handleSuccess = useCallback(
    (response: any) => {
      Swal.fire({
        icon: "success",
        title: "Cadastro efetuado com sucesso!",
        confirmButtonText: "Prosseguir",
        html: response.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => {
        app.setToken(response.token);
        navigate(response.redirectTo);
        window.scrollTo(0, 0);
      });
    },
    [app, navigate],
  );

  const handleError = useCallback(
    (error: any) => {
      Object.entries<any>(error.data).forEach(([field, messages]) => {
        setError(field as any, { type: "manual", message: messages[0] });
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: error.message,
      });
    },
    [setError],
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: "Erro ao carregar o reCAPTCHA",
        });

        return;
      }

      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando o seu cadastro",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      data.recaptcha_token = await executeRecaptcha();

      data.aceite_regulamento = data.aceite_regulamento === "S" ? "S" : "N";
      data.aceite_informacoes = data.aceite_informacoes === "S" ? "S" : "N";

      api.register(data).then(handleSuccess).catch(handleError);
    },
    [api, handleError, handleSuccess, executeRecaptcha],
  );

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section id="about" className="mt-5">
        <div className="container">
          <Row>
            <Col
              xs={12}
              md={10}
              className="d-flex flex-column align-items-center mb-2 mx-auto"
            >
              <Image width={240} src={LogoFullImage} className="mb-2" />
              <span className="fs-1 bg-tint">COMPRE E CONCORRA</span>
              <span className="fs-6 f-poppins text-center mb-4">
                Uma viagem "Tradicionalmente Não Tradicional" PARA O CHILE
              </span>
              <span className="fs-4 text-center mb-4">
                Em 1818, Paula recebeu 120 heróis em sua adega. Hoje, ela te
                recebe.
              </span>
            </Col>

            <Col xs={12} md={10} className="mx-auto p-0">
              <div className="locals">
                <div className="local">
                  <Image src={Local01} fluid />
                  <span className="fs-4">SANTIAGO, CL</span>
                </div>
                <div className="local">
                  <Image src={Local02} fluid />
                  <span className="fs-4">TORRES DEL PAINE</span>
                </div>
                <div className="local">
                  <Image src={Local03} fluid />
                  <span className="fs-4">VIÑA SANTA RITA</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section id="como-participar">
        <div className="container">
          <h1 className="text-center mb-4">COMO PARTICIPAR</h1>

          <Row className="mt-2">
            <Col xs={12} md={{ span: 6 }}>
              <div className="d-flex flex-column align-items-center steps mx-auto">
                <Image className="step" src={Step01} alt="" />
                <Image className="step" src={Step02} alt="" />
                <Image className="step" src={Step03} alt="" />
              </div>
            </Col>
            <Col xs={12} md={{ span: 6 }} className="mt-5 mt-lg-0">
              <Form
                ref={formRef}
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                autoCorrect="off"
                noValidate
              >
                <Row>
                  <Col xs={12} md={12}>
                    <Field
                      name="nome"
                      label="NOME COMPLETO*:"
                      register={register}
                      formState={formState}
                      required="Campo obrigatório"
                      validate={validateName}
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      name="cpf"
                      label="CPF*:"
                      register={register}
                      formState={formState}
                      as={InputMask}
                      mask="___.___.___-__"
                      replacement={{ _: /\d/ }}
                      required="Campo obrigatório"
                      validate={validateCPF}
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      as="select"
                      name="sexo"
                      label="SEXO*:"
                      register={register}
                      formState={formState}
                      required="Campo obrigatório"
                    >
                      <option value="" disabled>
                        SELECIONE UMA OPÇÃO
                      </option>
                      <option value="feminino">FEMININO</option>
                      <option value="masculino">MASCULINO</option>
                      <option value="outros">OUTROS</option>
                    </Field>
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      name="data_nascimento"
                      label="DATA DE NASCIMENTO*:"
                      register={register}
                      formState={formState}
                      as={InputMask}
                      mask="__/__/____"
                      replacement={{ _: /\d/ }}
                      required="Campo obrigatório"
                      validate={validateBirthDate}
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      name="telefone"
                      label="TELEFONE*:"
                      register={register}
                      formState={formState}
                      as={InputMask}
                      mask="(__) _____-____"
                      replacement={{ _: /\d/ }}
                      required="Campo obrigatório"
                      validate={validatePhone}
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      name="cidade"
                      label="CIDADE*:"
                      register={register}
                      formState={formState}
                      required="Campo obrigatório"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      name="estado"
                      label="ESTADO*:"
                      register={register}
                      formState={formState}
                      required="Campo obrigatório"
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      name="email"
                      label="E-MAIL*:"
                      type="email"
                      register={register}
                      formState={formState}
                      required="Campo obrigatório"
                      validate={validateMail}
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      name="email_confirmation"
                      label="CONFIRMAR E-MAIL*:"
                      type="email"
                      register={register}
                      formState={formState}
                      required="Campo obrigatório"
                      validate={validateMail}
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    <Field
                      name="password"
                      label="SENHA*:"
                      type="password"
                      register={register}
                      formState={formState}
                      required="Campo obrigatório"
                      minLength={8}
                    />
                  </Col>
                  <Col xs={12} md={12} className="mb-4">
                    <Field
                      name="password_confirmation"
                      label="CONFIRMAR SENHA*:"
                      type="password"
                      register={register}
                      formState={formState}
                      required="Campo obrigatório"
                      validate={(x: any) => {
                        return (
                          getValues("password") === x ||
                          "As senhas não coincidem"
                        );
                      }}
                    />
                  </Col>
                  <Col xs={12} md={12} className="ms-4 mb-3">
                    <Form.Check
                      value="S"
                      type="checkbox"
                      label={
                        <span className="fw-bold">
                          {"ACEITO TODOS OS "}
                          <a
                            href="/regulamento"
                            target="_blank"
                            className="text-brown"
                          >
                            TERMOS E CONDIÇÕES
                          </a>
                        </span>
                      }
                      isInvalid={!!formState.errors.aceite_regulamento}
                      {...register("aceite_regulamento", {
                        required: "Campo obrigatório",
                      })}
                    />
                    {formState.errors.aceite_regulamento?.message && (
                      <span className="text-small">
                        {formState.errors.aceite_regulamento?.message}
                      </span>
                    )}
                  </Col>
                </Row>

                {formState.errors.recaptcha_token && (
                  <Alert variant="danger" className="mb-3">
                    {formState.errors.recaptcha_token.message}
                  </Alert>
                )}

                <div className="d-flex">
                  <Button
                    type="submit"
                    variant="primary"
                    className="btn-tint button mx-auto"
                  >
                    ENVIAR
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </section>

      <section>
        <Container>
          <Row>
            <Col xs={12} md={10} className="bg-video mx-auto mb-5">
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/TXFraw3fLwY?si=JQLxzezsPU7p8itq&rel=0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col
            xs={12}
            md={10}
            className="d-flex flex-row align-items-top justify-content-between mx-auto p-0"
          >
            <div
              className="wines-footer"
              style={{ backgroundImage: `url(${WinesImage})` }}
            />
            <div
              className="woman-footer"
              style={{ backgroundImage: `url(${WomanFooterImage})` }}
            />
          </Col>
        </Row>
      </Container>
    </Suspense>
  );
}
